var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper el-row--padding"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_c('h3',[_vm._v("Vehicle Route Change Form")])]),_c('div',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v("Back ")])],1)]),_c('el-form',{attrs:{"label-position":'left',"label-width":"150px","enctype":"multipart/form-data","multiple":""}},[_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"5px"}},[_vm._v(" Route Permits ")]),_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Route'),
                      'is-required': _vm.isFieldRequired('Route'),
                    },attrs:{"label":"Route"}},[_c('el-col',{attrs:{"span":14}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select","name":"Route"},model:{value:(_vm.routePermit.route),callback:function ($$v) {_vm.$set(_vm.routePermit, "route", $$v)},expression:"routePermit.route"}},_vm._l((_vm.routes),function(item){return _c('el-option',{key:item.gid,attrs:{"label":item.name,"value":item.gid}})}),1),_c('br'),(_vm.errors.has('Route'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Route")))]):_vm._e()],1)],1)],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('file[]'),
                      'is-required': _vm.isFieldRequired('file[]'),
                    },attrs:{"label":"Route Permit"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"multiple":"multiple","action":"","id":"doc","auto-upload":false,"name":"file[]","on-change":_vm.handleChange,"on-remove":_vm.handleRemove,"file-list":_vm.routePermitCertificate}},[_c('el-button',{attrs:{"size":"small","type":"primary","round":""},model:{value:(_vm.routePermitCertificate),callback:function ($$v) {_vm.routePermitCertificate=$$v},expression:"routePermitCertificate"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),_c('div',[_c('a',{attrs:{"href":_vm.routePermit.routePermitCertificate,"target":"_blank"}},[_vm._v(_vm._s(_vm.routePermit.routePermitCertificateName))])]),(!_vm.routePermit.routePermitCertificate)?_c('small',{staticClass:"error",staticStyle:{"margin-top":"-10px"}},[_vm._v(" Upload files of extensions .png or .jpeg ")]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Issue Date'),
                      'is-required': _vm.isFieldRequired('Issue Date'),
                    },attrs:{"label":"Issue Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Issue Date","name":"Issue Date"},on:{"change":_vm.bsDateChange},model:{value:(_vm.routePermit.issuedDateBs),callback:function ($$v) {_vm.$set(_vm.routePermit, "issuedDateBs", $$v)},expression:"routePermit.issuedDateBs"}}),_c('br'),(_vm.errors.has('Issue Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Issue Date")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Expiry Date'),
                      'is-required': _vm.isFieldRequired('Expiry Date'),
                    },attrs:{"label":"Expiry Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Expiry Date","name":"Expiry Date"},on:{"change":_vm.bsDateChange1},model:{value:(_vm.routePermit.expiryDateBs),callback:function ($$v) {_vm.$set(_vm.routePermit, "expiryDateBs", $$v)},expression:"routePermit.expiryDateBs"}}),_c('br'),(_vm.errors.has('Expiry Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Expiry Date")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":10,"hidden":""}},[_c('el-form-item',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.routePermit.isActive),expression:"routePermit.isActive"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.routePermit.isActive)?_vm._i(_vm.routePermit.isActive,null)>-1:(_vm.routePermit.isActive)},on:{"change":function($event){var $$a=_vm.routePermit.isActive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.routePermit, "isActive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.routePermit, "isActive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.routePermit, "isActive", $$c)}}}}),_vm._v(" Is Active ")])],1)],1)],1),_c('el-row',[_c('el-col',[_c('el-form-item',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }