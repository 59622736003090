





































































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import commonstore from "@/store/modules/common";
import {
  GPSServiceProviderModule as gpsServiceStore,
  VehicleModule as vehicleStore,
  MetaModule as metaStore,
} from "@/store/modules";
import ownershipStore from "@/store/modules/ownership";
import { GpsDevice } from "@/store/models/registration/registration";
import { VehicleType } from "@/store/models/meta";
import NepaliDatePicker from "@/components/NepaliDatePicker.vue";
import { BSToAD } from "bikram-sambat-js";

@Component({
  components: {
    Input,
    Button,
    Form,
    NepaliDatePicker,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  devices: GpsDevice[] = [];
  vehicleType: VehicleType[] = [];
  vehicleOwnerId: number = 0;
  routePermitCertificate: File[] = [];
  data() {
    return {
      options: [
        {
          value: "Commercial",
          label: "Commercial",
        },
        {
          value: "Non-Commercial",
          label: "Non-Commercial",
        },
      ],
      value: "",
    };
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get routePermit() {
    return vehicleStore.routePermit;
  }

  handleChange(file: any, fileList: any) {
    let vm = this;
    const docsExt = file.raw.name.split(".").length - 1;
    if (
      file.raw.name.split(".")[docsExt] === "jpeg" ||
      file.raw.name.split(".")[docsExt] === "png" ||
      file.raw.type === "image/jpeg" ||
      file.raw.type === "image/png"
    ) {
      vm.routePermitCertificate = [file.raw];
      vm.routePermit.routePermitCertificate = file.raw;
    } else {
      this.$snotify.error("Please upload .png or .jpeg file");
      file = "";
      vm.routePermitCertificate = [];
      vm.routePermit.routePermitCertificate = null;
    }
  }
  handleRemove(file: any, fileList: any) {
    let vm = this;
    vm.routePermitCertificate = [];
    vm.routePermit.routePermitCertificate = null;
  }

  bsDateChange(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.routePermit.issuedDate = bsAD;
      this.routePermit.issuedDateBs = e;
    }
  }
  bsDateChange1(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.routePermit.expiryDate = bsAD;
      this.routePermit.expiryDateBs = e;
    }
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();
    if (this.routePermit.id) {
      // Remove File Fields without file
      this.removeFiles(this.routePermit, "routePermitCertificate");
    }
    let i = vm.$route.query["id"];

    await vehicleStore.changeVehicleRoutePermit(i);
    this.$snotify.success("Vehicle Route Changed Successfully");
    commonstore.hideLoading();
    console.log(this.vehicleOwnerId);
    if (this.vehicleOwnerId && this.vehicleOwnerId != 0) {
      this.$router.push(
        AdminRouter.VehicleApproval + "?vehicle_owner=" + this.vehicleOwnerId
      );
    } else {
      this.$router.push(AdminRouter.AllVehicleList);
    }
  }

  async cancelForm() {
    if (this.vehicleOwnerId && this.vehicleOwnerId != 0) {
      this.$router.push(
        AdminRouter.VehicleApproval + "?vehicle_owner=" + this.vehicleOwnerId
      );
    } else {
      this.$router.push(AdminRouter.AllVehicleList);
    }
  }

  async created() {
    vehicleStore.resetField();
    let vm = this;
    this.vehicleOwnerId = commonstore.vehicleOwnerId;
    const id = vm.$route.query["id"];
    await metaStore.loadRoutesDD();
    if (id) {
      vm.isCreate = false;

      await vehicleStore.getVehicleById(id);
    } else {
      vehicleStore.resetField();
    }
  }

  removeFiles(property: any, fieldName: any) {
    let field = property[fieldName];
    if (field === undefined || field === null || typeof field === "string") {
      delete property[fieldName];
    }
  }

  get routes() {
    return metaStore.routesList;
  }

  mounted() {}
  updated() {}
}
